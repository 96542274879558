/* External dependencies */
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependence */
import Header from '@components/website/header/Header';
import TermsOfUse from '@components/website/terms-of-use/TermsOfUse';
import Layout from '@shared/ui/layout/WebLayout';

export default function termsOfUsePage({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;

  const { t } = useI18next();

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', t('termsOfUse'))}</title>
      </Helmet>
      <Header />
      <TermsOfUse />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
