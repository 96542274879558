/* External dependencies */
import { Container, Div, Text } from 'atomize';
import React from 'react';

/* Local dependence */
import MainTitle from '@shared/ui/titles/MainTitle';
import { termsOfUseData } from './termsOfUseData';

export default function TermsOfUse() {
  return (
    <Container>
      <Div p={{ t: '100px', b: '130px' }}>
        <MainTitle title="termsOfUse" tag="h1" />
        <Text m={{ b: '30px' }}>
          Настоящее Пользовательское соглашение (далее — Соглашение) заключается
          между Обществом с ограниченной ответственностью «Прораб» (г. Бишкек,
          ул. Тыналиева, д. 2/22, кв. 20), именуемым в дальнейшем «Компания», и
          любым лицом, намеревающимся использовать и/или использующим приложение{' '}
          <Text tag="strong" textWeight="600">
            Medcheck
          </Text>{' '}
          (далее — Приложение), именуемым в дальнейшем «Пользователь».
        </Text>
        {termsOfUseData.map((item, index) => {
          const titleNumber = index + 1;

          return (
            <Div key={index}>
              <Text tag="h4" textSize="display1" m={{ b: '15px' }}>
                <Text tag="strong" m={{ r: '10px' }}>
                  {titleNumber}.
                </Text>
                {item.title}
              </Text>
              {item.content.map((content, index) => {
                const contentLength = item.content.length - 1;

                if (index === contentLength) {
                  return (
                    <Text key={index} m={{ b: '25px' }}>
                      {content.text}
                      {content.link && (
                        <a href="mailto:support@medcheck.kg"> {content.link}</a>
                      )}
                    </Text>
                  );
                }

                return (
                  <Text key={index} m={{ b: '10px' }}>
                    {content.text}
                    {content.link && (
                      <a href="mailto:support@medcheck.kg"> {content.link}</a>
                    )}
                  </Text>
                );
              })}
            </Div>
          );
        })}
      </Div>
    </Container>
  );
}
