export const termsOfUseData = [
  {
    title: 'Общее положение',
    content: [
      {
        text: '1.1.    Текст настоящего Соглашения является публичной офертой (предложением) Компании согласно статье 396 Гражданского Кодекса Кыргызской Республики, адресованной неопределенному кругу лиц и содержащей предложение Компании о заключении с каждым Пользователем соглашения, регулирующего следующие аспекты взаимоотношений Компании и Пользователя при использовании Приложения на условиях, указанных в данном документе:',
      },
      {
        text: '1.1.1.    порядок электронного взаимодействия Компании и Пользователя (раздел 7);',
      },
      {
        text: '1.1.2.    правила использования Приложения Пользователем (лицензионный договор) (разделы 2-4);',
      },
      {
        text: '1.1.3.    согласие на обработку персональных данных и правила обработки персональных данных (раздел 5).',
      },
      {
        text: '1.2.    Акцептом настоящей оферты согласно статье 399 и статье 1042 Гражданского кодекса Кыргызской Республики являются любые действия Пользователя по использованию Приложения, в том числе первое открытие любой страницы Приложения на любом устройстве Пользователя.',
      },
      {
        text: '1.3.	  Пользователем Приложения признаются: пациенты, медицинские работники, медицинские учреждения, организации и компании, предоставляющие фармацевтические и другие услуги в области медицины и медицинского образования, вступающие в партнёрские отношения с Компанией в рамках Приложения.',
      },
      {
        text: '1.4.    Пользователь дает свое согласие на обработку персональных данных по правилам раздела 5 путем проставления галочки в соответствующих полях при использовании форм Приложения или нажатия кнопок «Отправить», «Сохранить» или «Зарегистрироваться», которые предусматривают ввод и направление персональных данных Пользователя.',
      },
      {
        text: '1.5.    Действие данного Соглашения распространяется как на период пользования Приложением Компании независимо от типа устройства или операционной системы без открытия профиля, так и до его удаления.',
      },
      {
        text: '1.6.    Действие данного Соглашения распространяется также на Пользователей Приложения, с которыми Компания заключила отдельные соглашения (врачи, медицинские организации и другие учреждения, далее Партнёры) лишь в той части, которая не противоречит таким соглашениям.',
      },
      {
        text: '1.7.    Использование Приложения, в том числе его просмотр, поиск информации, использование сервисов (далее — использование Приложения) возможно только при условии полного и безоговорочного принятия условий настоящей оферты (акцепта) в форме, установленной настоящим Соглашением.',
      },
      {
        text: '1.8.	   При несогласии с условиями настоящего Соглашения Пользователь обязан немедленно прекратить использование Приложения.',
      },
    ],
  },
  {
    title: 'Предмет соглашения',
    content: [
      {
        text: '2.1.    По настоящему Соглашению Компания предоставляет Пользователю неисключительную лицензию на использование Приложения. Приложение представляет собой составной (сложный) объект интеллектуальной собственности в составе компьютерных программ, баз данных, графического контента и других произведений, объединенных для обеспечения нормального функционирования Приложения и использования его возможностей.',
      },
      {
        text: '2.2.    Использование Приложения Пользователями осуществляется:',
      },
      {
        text: '2.2.1.	 в личных целях, не связанных с извлечением материальной прибыли, для осуществления поиска специалистов, организации записи на прием к конкретному специалисту либо в конкретное медицинское учреждение, получения информационных и образовательных услуг;',
      },
      {
        text: '2.2.2.	в коммерческих целях – при взаимодействии Пользователей и Партнёров для оказания Пользователям медицинских, информационных и образовательных услуг (далее — Консультации).',
      },
      {
        text: '2.3.	 При необходимости использования функционала Приложения в коммерческих целях (для размещения информации о платных медицинских, информационных и образовательных услугах, предоставляемых Пользователем (далее Партнёром), и для обеспечения получения заявок от других Пользователей на оказание таких услуг в целях извлечения прибыли) Партнёр обязан заключить с Компанией отдельное соглашение путем присоединения к стандартным условиям лицензионного договора.',
      },
      {
        text: '2.4.	Предоставление Пользователям информационных возможностей для установления контактов Партнёров Компании и заключения с ними сделок в отношении оказания медицинских, консультативных и иных услуг осуществляется Компанией бесплатно.',
      },
      {
        text: '2.5.	Компания является оператором информационной системы для организации взаимодействия между Пользователем и Партнёрами. Компания не является медицинской организацией либо представителем медицинской организации, не оказывает какие-либо медицинские услуги. Компания не несет ответственность за качество оказания медицинских и информационных услуг третьими лицами, соблюдение стандартов и лицензионных требований. Компания не несет ответственность за любые действия Пользователя и за неисполнение обязательств Пользователей.',
      },
    ],
  },
  {
    title: 'Сведения, размещаемые в Приложении',
    content: [
      {
        text: '3.1.	Приложение является информационно-аналитическим сервисом, обеспечивающим взаимодействие Пользователей и Партнёров в целях:',
      },
      {
        text: '3.1.1.	поиска врачей требуемой специализации, квалификации, опыта работы;',
      },
      {
        text: '3.1.2.	получения/предоставления медицинских услуг: лабораторной и инструментальной диагностики, консультаций специалистов, процедурных и других медицинских манипуляций;',
      },
      {
        text: '3.1.3.	получения информации о доступных временных интервалах для записи на прием и для организации такой записи. Приложение обеспечивает взаимодействие Пользователей и Партнёров для получения Консультаций Пользователями;',
      },
      {
        text: '3.1.4.	получения/предоставления справочных, образовательных, консультативных и иных услуг в сфере медицины, не противоречащих законодательству;',
      },
      {
        text: '3.1.5.	предоставления информационных и образовательных услуг в рамках раздела “Meducation” исключительно для медицинских работников и студентов медициснких учебных заведений;',
      },
      {
        text: '3.1.6.	Meducation – образовательная платформа на базе Приложения, позволяющая медицинским работникам:',
      },
      {
        text: '3.1.6.1.	получать дополнительные знания, просматривая видеоуроки, опубликованные в разделе со стороны Партнёра Приложения;',
      },
      {
        text: '3.1.6.2.	зарабатывать баллы за правильные ответы в тестах к видеоурокам;',
      },
      {
        text: '3.1.6.3.	обменивать заработанные баллы на подарки и призы от Партнёра Приложения, продвигающего свои интересы посредством настоящего раздела.',
      },
      {
        text: '3.1.7.	возможности оставить комментарий или отзыв об услугах, полученных у Партнёров Приложения.',
      },
      {
        text: '3.2.	Для получения услуг согласно пункту 3.1. Соглашения Пользователь взаимодействует с Партнёром (-ами). ',
      },
      {
        text: '3.3.	Информация о врачах, размещаемая в Приложении, получается Компанией непосредственно от медицинских учреждений либо самих врачей путём письменных и устных переговоров, записи видеороликов с их участием и дальнейшим их размещением в Приложении. ',
      },
      {
        text: '3.4.	Компания принимает все разумные меры для проверки информации, предоставляемой медицинскими организациями и врачами для размещения в Приложении, в том числе о наличии лицензии или аккредитации. Вместе с тем, Компания не осуществляет регулярную проверку указанных сведений на предмет актуальности. В связи с этим Пользователь обязуется при обращении к определенному врачу самостоятельно убедиться о наличии у него необходимого образования, квалификации, аттестации и лицензии медицинского учреждения.',
      },
      {
        text: '3.5.	Пользователь может оставить отзыв в Приложении, касающийся непосредственно объекта комментирования, о качестве полученных услуг у него и соглашается со статусом общедоступности своего отзыва.',
      },
      {
        text: '3.6.	При размещении комментариев или отзывов Пользователь должен действовать добросовестно и разумно, уважать честь, достоинство, деловую репутацию других Пользователей, деловую репутацию организаций, вести себя корректно и вежливо.',
      },
      {
        text: '3.7.	Компания гарантирует, что все комментарии и отзывы, размещаемые на страницах Приложения, получены от Пользователей, которые воспользовались услугами соответствующих врачей или медицинских организаций. Компания принимает все разумные меры для предотвращения размещения или удаления комментариев и отзывов с заведомо недостоверной либо не соответствующей законодательству информацией.',
      },
      {
        text: '3.8.	Компания не гарантирует достоверность информации, содержащейся в комментариях и отзывах Пользователей. Компания удаляет комментарии и отзывы по требованию медицинских учреждений, врачей либо Пользователей только в случае предоставления доказательств их явной недостоверности, либо противоречия законодательству. Компания удаляет комментарии и отзывы по требованиям компетентных государственных органов, предъявленных в порядке, установленном законом. Компания вправе в любое время удалить любые комментарии, отзывы и любую иную информацию с Приложения по собственной инициативе.',
      },
      {
        text: '3.9.	Если Пользователь считает, что какие-либо комментарии (отзывы) нарушают законодательство Кыргызской Республики и/или международные нормы, настоящее Соглашение, содержат коммерческую тайну или способны нанести ущерб физическим или юридическим лицам, Пользователь имеет право сообщить об этом Компании, направив письмо по адресу:',
        link: 'support@medcheck.kg',
      },
      {
        text: '3.10.	Компания не участвует в спорах, не дает оценку размещенным комментариям (отзывам) и оставляет за собой право не удалять комментарии (отзывы) в случае отсутствия однозначной возможности оценить соответствие сообщения законодательству и настоящему Соглашению.',
      },
      {
        text: '3.11.	Компания имеет право использовать информацию о Пользователе, привлекать Пользователей в качестве соответчиков, свидетелей, третьих лиц в суде в случае предъявления к Компании претензий, исков, связанных с комментарием (отзывом), размещенным этим Пользователем. Пользователь, разместивший комментарий (отзыв), должен быть готов в случае необходимости представить доказательства законности, обоснованности информации в комментарии (отзыве).',
      },
      {
        text: '3.12.	Рейтинг врачей формируется Компанией на основе установленных Компанией критериев. Компания не обязуется сообщать Пользователям принципы и критерии расчета рейтинга врачей, хотя и вправе это сделать, полностью или частично.',
      },
      {
        text: '3.13.	Информация о медицинских услугах, специализации врачей, болезнях, методах диагностики и способах лечения носит справочный характер. Компания не гарантирует ее достоверность и соответствие современным научным достижениям. Компания настоятельно рекомендует обращаться за квалифицированной медицинской помощью непосредственно к врачам требуемой специализации, в том числе к тем, профили которых представлены в Приложении.',
      },
    ],
  },
  {
    title: 'Лицензия',
    content: [
      {
        text: '4.1.	Компании принадлежат исключительные права на Приложение, в том числе исключительные права на любые входящие в его состав результаты интеллектуальной деятельности, включая программный код, размещенные в Приложении базы данных (сведения о медицинских организациях и врачах), произведения дизайна, тексты, а также средства индивидуализации (фирменное наименование, товарные знаки, знаки обслуживания, коммерческие обозначения). Использование Приложения не предусматривает передачу прав на Приложение или его компоненты. Пользователю предоставляется ограниченное право на использование Приложения в соответствии с условиями Соглашения. Такое право может быть прекращено в любое время в соответствии с условиями Соглашения и иных соглашений между сторонами.',
      },
      {
        text: '4.2.	Пользователь обязуется не использовать размещенные в Приложении результаты интеллектуальной деятельности (в том числе, но не ограничиваясь: изображения, тексты, программный код) без предварительного письменного согласия Компании.',
      },
      {
        text: '4.3.	Все сервисы Приложения предоставляются бесплатно и в состоянии «как есть». Компания не гарантирует доступность Приложения в любой момент. Компания имеет право в любой момент отказать любому Пользователю в использовании Приложения при нарушении условий Соглашения.',
      },
      {
        text: '4.4.	Пользователь не вправе требовать внесения каких-либо изменений в сервисы либо данные Приложения. Компания не несет ответственность за коммерческую пригодность Приложения, не гарантирует соответствие Приложения специальным требованиям Пользователей или возможность настройки разделов Приложения в соответствии с предпочтениями Пользователя, а также не гарантирует, что программное обеспечение Приложения полностью свободно от дефектов и ошибок, и должно функционировать бесперебойно и в обязательном порядке.',
      },
      {
        text: '4.5.	Использование Приложения осуществляется Пользователем исключительно под свою ответственность и на собственный риск. Компания не гарантирует должное функционирование Приложения и не несет ответственность за вред, причиненный Пользователю в результате использования Приложения. Компания не несет ответственность за риск наступления неблагоприятных последствий, которые наступят или могут наступить вследствие несоответствия, используемого Пользователями оборудования, иного программного обеспечения или каналов связи установленным требованиям по защите персональных данных от несанкционированного (противоправного) посягательства третьих лиц.',
      },
      {
        text: '4.6.	Компания прилагает все разумные усилия, предотвращающие сбои и неполадки в работе Приложения, однако не гарантирует его бесперебойную работу, не несет ответственность за нее и не обязуется уведомлять Пользователей о перебоях.',
      },
      {
        text: '4.7.	Пользователь не вправе использовать Приложение для рассылки сообщений рекламного характера и иных действий, не связанных непосредственно с использованием Приложения. Пользователь не вправе использовать программный код Приложения, какой-либо его контент (включая, но не ограничиваясь: базы данных, текст, элементы дизайна, графические изображения) без предварительного письменного согласия Компании (в том числе воспроизводить, копировать, перерабатывать, распространять в любом виде).',
      },
    ],
  },
  {
    title: 'Конфиденциальная информация',
    content: [
      {
        text: '5.1.	Пользователь дает свое согласие на обработку своих персональных данных (фамилию, имя, отчество, фотографию, контактные данные, дату рождения, группу крови, адрес, профессиональные данные) предоставляемых при использовании Приложения, в том числе сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, предоставление персональных данных (в том числе медицинской организации или врачу в зависимости от ситуации), передачу (в том числе путем распространения, доступа), обезличивание, блокирование, удаление и уничтожение. Пользователь дает свое согласие на сбор, хранение и передачу своих персональных данных третьим лицам, оказывающим по договору с Компанией услуги по обработке заявок и звонков Пользователей (сотрудникам call-центра).',
      },
      {
        text: '5.2.	При использовании некоторых сервисов Приложения Пользователь сообщает Компании сведения о факте обращения (в том числе намерении обратиться) за медицинской помощью к врачу определенной специализации.',
      },
      {
        text: '5.3.	Пользователь дает разрешение на сбор, хранение и обработку данной информации, а также на предоставление ее медицинскому учреждению и/или врачу, к которому пользователь обращается за оказанием медицинских услуг.',
      },
      {
        text: '5.4.	Пользователь дает свое согласие на сбор, хранение и передачу данных своего списка контактов для связи с ними в экстренных случаях.',
      },
      {
        text: '5.5.	Пользователь не обязан, но может предоставить Компании информацию о состоянии своего здоровья для получения возможности использовать более развернутый функционал Приложения. Пользователь вправе не предоставлять сотрудникам Компании (или call-центра) какие-либо дополнительные сведения или информацию при использовании Приложения, помимо своего полного имени и контактного номера телефона.',
      },
      {
        text: '5.6.	Пользователь настоящим уведомлен, понимает и соглашается с тем, что при использовании Приложения для целей предоставления Пользователю функциональных возможностей Приложения Компании в автоматическом режиме анонимно передается следующая информация: тип операционной системы устройства Пользователя, версия и идентификатор браузера, статистика использования функций Сервиса, данные местонахождения устройства (если применимо), а также иная техническая информация.',
      },
      {
        text: '5.7.	Пользователь даёт согласие Компании на проведение исследований передаваемой и полученной Пользователем информации в обезличенном виде.',
      },
      {
        text: '5.8.	Пользователь может позволить или запретить поступление информационных сообщений от Приложения, а также ограничить доступ Приложения к списку его контактных данных.',
      },
      {
        text: '5.9.	Компания обязуется принимать меры по охране конфиденциальности информации, полученной от Пользователей, и не разглашать ее третьим лицам, кроме указанных в Соглашении или законодательстве случаев.',
      },
      {
        text: '5.10.	Компания может использовать общеотраслевую технологию «куки» (cookies). Куки — это небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере, который использует Пользователь Приложения, позволяющий Компании сохранять персональные настройки и предпочтения Пользователя, а также собирать неличную информацию о нем.',
      },
      {
        text: '5.11.	Компания собирает сведения о фактически проведенных приемах у того или иного врача исключительно с целью определения свободного времени для записи, для передачи таких данных страховым компаниям, если у Пользователя оформлена соответствующая программа медицинского страхования. ',
      },
      {
        text: '5.12.	Компания может использовать полученные персональные данные для следующих целей:',
      },
      {
        text: '5.12.1.	обеспечивать качественную работу Приложения;',
      },
      {
        text: '5.12.2.	вносить изменения в Приложение для улучшения его работы;',
      },
      {
        text: '5.12.3.	отправлять сообщения Пользователям, касающиеся использования Приложения, в том числе о проводимых акциях и специальных предложениях;',
      },
      {
        text: '5.12.4.	предоставлять Пользователю поддержку при использовании Приложения в случае возникновения у Пользователя трудностей, в том числе с выбором конкретного врача или медицинской организации;',
      },
      {
        text: '5.12.5.  отправлять Пользователям рекламные материалы и сообщать о специальных предложениях своих партнеров;',
      },
      {
        text: '5.12.6.	получать отзывы и пожелания Пользователей по работе Приложения, в том числе по электронной почте или путем проведения телефонных опросов.',
      },
      {
        text: '5.13.	Сведения о факте обращения Пользователя за оказанием медицинской помощи, состоянии его здоровья и диагнозе, иные сведения, полученные при его медицинском обследовании и лечении, составляют врачебную тайну, которую Партнёр обязуется не разглашать за исключением случаев, предусмотренных действующим законодательством Кыргызской Республики. ',
      },
    ],
  },
  {
    title: 'Ограничение ответственности',
    content: [
      {
        text: '6.1.	Компания ни при каких обстоятельствах не несет ответственность за качество медицинских услуг, оказываемых врачами или медицинскими организациями, в том числе тех, профили которых размещены в Приложении.',
      },
      {
        text: '6.2.	Компания не несёт ответственность за убытки и ущерб, причиненные Пользователю в результате предоставления недостоверной информации и получения Пользователем консультаций, рекомендаций и/или информационных услуг у Партнёров Компании.',
      },
      {
        text: '6.3.	Компания не несет ответственность перед Пользователем и не возмещает Пользователю убытки, возникшие или возникновение которых возможно в связи с перебоями в работе провайдера, иных лиц или служб, доступом к Интернет-ресурсу.',
      },
      {
        text: '6.4.	Компания ни при каких обстоятельствах не несет ответственность за действия Пользователей.',
      },
      {
        text: '6.5.	Пользователь самостоятельно несет всю имущественную ответственность (возмещение убытков, штрафные санкции, судебные расходы), возникшую в связи с предъявлением третьими лицами каких-либо претензий, исков, в том числе связанных с защитой интеллектуальной собственности касательно комментария (отзыва), размещенного Пользователем, в полном объеме.',
      },
      {
        text: '6.6.	Незнание положений настоящего Соглашения не освобождает Пользователей от ответственности за их нарушение.',
      },
      {
        text: '6.7.	На все комментарии (отзывы), размещенные в Приложении, распространяется авторское право. Все заимствованные материалы должны иметь указание имени автора, если оно указано в источнике. Пользователь обязуется не присваивать себе авторство чужих текстов или изображений. Пользователь гарантирует законность размещения информации Приложении, в том числе соблюдение прав третьих лиц.',
      },
      {
        text: '6.8.	Размещая свой комментарий (отзыв) в Приложении, Пользователь безвозмездно передает Компании права на его использование в любой форме и любым способом, включая, но не ограничиваясь: воспроизведение, распространение, показ, перевод, переделка (переработка), передача в эфир, по кабелю, проводам или с помощью иных аналогичных средств.',
      },
      {
        text: '6.9.	Компания является правообладателем всех материалов, размещенных в Приложении. Доступ к материалам Приложения предоставляется исключительно для личного использования и ознакомления.',
      },
      {
        text: '6.10.	Дальнейшее воспроизведение, распространение любым способом, копирование, передача в эфир для всеобщего сведения, перевод, переделка и использование любым иным способом материалов, размещенных в Приложении, для личного использования, без преследования коммерческих целей, возможно только с указанием активной ссылки на Приложение или упоминанием Приложения как источника информации (при использовании материалов, размещенных в Приложении, в социальных сетях).',
      },
    ],
  },
  {
    title: 'Электронное взаимодействие',
    content: [
      {
        text: '7.1.	Компания предоставляет Пользователям, медицинским организациям и врачам возможность использовать специальный раздел Приложения — Личный кабинет, доступ к которому предоставляется после прохождения процедуры регистрации и присвоения уникального идентификатора (логина) и пароля.',
      },
      {
        text: '7.2.	Сочетание уникального идентификатора (логина) и пароля от Личного кабинета Пользователя считается простой электронной подписью соответствующего Пользователя. Порядок электронного взаимодействия Пользователей определяется данным Соглашением.',
      },
      {
        text: '7.3.	Для незарегистрированных Пользователей простой электронной подписью признается уникальный электронный почтовый адрес или номер телефона такого Пользователя, указанный им при использовании Приложения (логин и пароль учетной записи почтового сервера или номер телефона и уникальный СМС-код).',
      },
      {
        text: '7.4.	Все сообщения Пользователей, сделанные через Личный кабинет или с использованием электронных почтовых адресов, в том числе предоставление согласий и подписание документов посредством функционала Личного кабинета, считаются сделанными и направленными Пользователем, подписанными простой электронной подписью и приравниваются к письменным документам, равнозначным документам на бумажном носителе, при этом приложение печати к таким документам не требуется.',
      },
      {
        text: '7.5.	Пользователь обязан сохранять конфиденциальность уникального идентификатора и пароля, которые используются для получения доступа к Личному кабинету.',
      },
      {
        text: '7.6.	Пользователь может отправлять свои вопросы и претензии, связанные с использованием и/или невозможностью использования Приложения, а также возможным нарушением законодательства и/или прав третьих лиц, которые должны направляться через форму обратной связи или по адресу',
        link: 'support@medcheck.kg',
      },
    ],
  },
  {
    title: 'Заключительные и переходные положения',
    content: [
      {
        text: '8.1.	Настоящее Соглашение действует в течение всего периода использования Приложения Пользователем.',
      },
      {
        text: '8.2.	Компания вправе:',
      },
      {
        text: '8.2.1.	в любое время изменять условия настоящего Соглашения, публикуя новую редакцию в Приложении. Актуальная версия Соглашения публикуется в Приложении и становится обязательной для Пользователя с момента опубликования. Пользователь обязуется регулярно просматривать опубликованный в Приложении текст Соглашения с целью ознакомления с изменениями;',
      },
      {
        text: '8.2.2.	использовать информацию о действиях Пользователей в целях улучшения работы Приложения;',
      },
      {
        text: '8.2.3.	закрыть, приостановить работу, изменить Приложение либо его часть без предварительного уведомления Пользователя;',
      },
      {
        text: '8.2.4.	расторгнуть в одностороннем порядке настоящее Соглашение при получении от уполномоченных государственных органов сведений, подтверждающих, что информация, предоставленная Пользователем при регистрации учетной записи, иной авторизации в Приложении, не соответствует действительности (за исключением фактов наличия в ней грамматических ошибок), а также при регистрации учетной записи либо иной авторизации использования информации, противоречащей требованиям законодательства Кыргызской Республики, в том числе нарушающей права и законные интересы третьих лиц.',
      },
      {
        text: '8.3.	До завершения процессов разработки, принятия и вступления в силу нормативно-правовых актов Кыргызской Республики, касающихся регулирования порядка и условий оказания медицинских услуг посредством телемедицинских технологий, Консультации и иные услуги, которые оказываются Пользователю посредством Приложения признаются исключительно информационными услугами и не должны рассматриваться в качестве вида медицинской помощи. Компания предпримет все возможные усилия для соблюдения законодательства Кыргызской Республики в сфере телемедицины, как только оно будет принято.',
      },
    ],
  },
];
