/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function GooglePlayIcon({
  width = 25,
  height = 26,
  style,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.566541 25.125L0.488167 25.0543C0.181216 24.7479 0 24.2722 0 23.6561V23.8011V1.79515C0 1.79316 0 1.79167 0 1.79008C0 1.79197 0 1.79356 0 1.79555V1.94092C0 1.27386 0.211279 0.771372 0.565274 0.470078L13.6342 12.7979L0.566541 25.125ZM0 1.78888C0 1.73406 0.00158214 1.68082 0.00453563 1.62839C0.00158214 1.68043 0 1.73406 0 1.78888ZM0.00453563 1.626C0.00453563 1.6253 0.00453465 1.62451 0.00485109 1.62371C0.00453465 1.62451 0.00453563 1.6253 0.00453563 1.626ZM0.00485109 1.61903C0.00485109 1.61863 0.00485109 1.61824 0.00485109 1.61784C0.00485109 1.61824 0.00485109 1.61863 0.00485109 1.61903Z"
        fill="url(#paint0_linear_11683_63740)"
      />
      <path
        d="M17.9904 17.0547L18.0898 17.0015L23.2509 14.2352C23.743 13.9713 24.0709 13.6514 24.2345 13.3129C24.0713 13.6514 23.7433 13.9717 23.2509 14.2356L18.0898 17.0019L17.9904 17.0547V17.0547ZM17.9917 16.9089L13.6348 12.7983L17.9913 8.68832L23.2509 11.507C23.9188 11.865 24.2938 12.3264 24.3564 12.7979C24.3564 12.7983 24.3564 12.7991 24.3564 12.7998C24.2938 13.2701 23.9188 13.7319 23.2509 14.0898L17.9917 16.9089"
        fill="url(#paint1_linear_11683_63740)"
      />
      <path
        d="M1.49748 25.5938C1.13241 25.5938 0.813854 25.4818 0.565446 25.2704L0.565869 25.27C0.814278 25.4814 1.13325 25.5938 1.49832 25.5938C1.52997 25.5938 1.56214 25.593 1.59474 25.5915C1.56172 25.593 1.52912 25.5938 1.49748 25.5938V25.5938ZM1.4979 25.448C1.13283 25.4484 0.814278 25.336 0.565869 25.1246V25.1243L13.6335 12.7972L17.9905 16.9079L2.67961 25.1141C2.256 25.3407 1.85464 25.448 1.4979 25.448V25.448ZM0.562177 25.2677C0.539076 25.2479 0.516397 25.2269 0.494141 25.2051L0.562177 25.2677Z"
        fill="url(#paint2_linear_11683_63740)"
      />
      <path
        d="M13.6334 12.7969L0.564453 0.469009C0.812862 0.257964 1.13099 0.146023 1.49564 0.146023C1.85333 0.146023 2.255 0.253686 2.67946 0.480651L17.9899 8.68692L13.6334 12.7969V12.7969ZM18.0884 8.59409L2.67946 0.335375C2.255 0.10831 1.85333 0.000650227 1.49564 0.000650227C1.49396 0.000650227 1.4928 0.000650227 1.49111 0.000650227C1.49322 0.000650227 1.4948 0.000650227 1.49733 0.000650227C1.85417 0.000650227 2.25543 0.107913 2.67946 0.334977L18.0884 8.59409V8.59409Z"
        fill="url(#paint3_linear_11683_63740)"
      />
      <path
        d="M1.59552 25.5938C1.92715 25.5758 2.29433 25.4682 2.6804 25.2618L17.99 17.0559L2.6804 25.2618C2.29465 25.4686 1.92715 25.5758 1.59552 25.5938V25.5938ZM0.566232 25.2727L0.562963 25.27C0.564123 25.2708 0.565388 25.2719 0.566232 25.2727ZM0.494927 25.2074L0.488281 25.2012V25.2008C0.490813 25.2031 0.492817 25.2054 0.494927 25.2074Z"
        fill="black"
      />
      <path
        d="M17.9902 17.0547L18.0896 17.0015L17.9902 17.0547Z"
        fill="url(#paint4_linear_11683_63740)"
      />
      <path
        d="M1.49911 25.5938C1.13404 25.5938 0.815064 25.4814 0.566655 25.27L0.566232 25.2704C0.565388 25.2696 0.564123 25.2685 0.562963 25.2677L0.494927 25.2051C0.492817 25.2031 0.490813 25.2008 0.488281 25.1985L0.566655 25.1246C0.815064 25.336 1.13362 25.4484 1.49869 25.448C1.85543 25.448 2.25678 25.3407 2.6804 25.1141L17.9912 16.9079L18.0893 17.0004L17.99 17.0536L2.6804 25.2595C2.29433 25.4659 1.92715 25.5735 1.59552 25.5915C1.56293 25.593 1.53075 25.5938 1.49911 25.5938"
        fill="url(#paint5_linear_11683_63740)"
      />
      <path
        d="M0.488167 25.2031C0.181216 24.8968 0 24.421 0 23.805V23.8046C0 24.421 0.181216 24.8964 0.488167 25.2027V25.2031"
        fill="black"
      />
      <path
        d="M0.488165 25.1953C0.181215 24.8889 0 24.4136 0 23.7972V23.6522C0 24.2682 0.181215 24.744 0.488165 25.0503L0.566539 25.1211L0.488165 25.1953"
        fill="url(#paint6_linear_11683_63740)"
      />
      <path
        d="M0.488281 25.1953L0.566658 25.1211V25.1214L0.488281 25.1953"
        fill="url(#paint7_linear_11683_63740)"
      />
      <path
        d="M24.2344 13.3125C24.3155 13.1445 24.3563 12.972 24.3563 12.7994C24.3563 12.7991 24.3563 12.7987 24.3563 12.7987C24.3563 12.9716 24.3155 13.1441 24.2344 13.3125Z"
        fill="black"
      />
      <path
        d="M18.0883 17L17.9902 16.9075L23.2494 14.0884C23.9174 13.7305 24.2923 13.2687 24.355 12.7983C24.355 12.971 24.3142 13.1435 24.2331 13.3114C24.0695 13.65 23.7415 13.9699 23.2494 14.2337L18.0883 17Z"
        fill="url(#paint8_linear_11683_63740)"
      />
      <path
        d="M24.355 12.7969V12.7965C24.3545 12.276 23.9858 11.7556 23.2494 11.3607L18.0883 8.59438H18.0879L18.0883 8.59408L23.2494 11.3603C23.9866 11.7552 24.3558 12.2764 24.355 12.7969Z"
        fill="#404040"
      />
      <path
        d="M24.3554 12.7969C24.2927 12.3254 23.9178 11.864 23.2499 11.506L17.9902 8.68732L18.0883 8.59478H18.0888L23.2499 11.3611C23.9862 11.756 24.355 12.2764 24.3554 12.7969Z"
        fill="url(#paint9_linear_11683_63740)"
      />
      <path
        d="M0 1.78906C0 1.78866 0 1.78827 0 1.78787C0 1.78827 0 1.78866 0 1.78906ZM0.00453562 1.62737C0.00453562 1.62658 0.00453562 1.62578 0.00453562 1.62498C0.00453562 1.62578 0.00453562 1.62658 0.00453562 1.62737ZM0.00485109 1.62269C0.00485109 1.6211 0.00485109 1.61961 0.00485109 1.61802C0.00485109 1.61961 0.00485109 1.6211 0.00485109 1.62269ZM0.00485109 1.61683C0.0659248 0.594638 0.655144 0.0041914 1.48866 0.000708817C1.12728 0.00269886 0.812522 0.114639 0.566118 0.324091V0.323693L0.563164 0.326478C0.537216 0.348567 0.512533 0.371951 0.488167 0.396031C0.211278 0.672348 0.036601 1.08628 0.00485109 1.61683Z"
        fill="#404040"
      />
      <path
        d="M0 1.9375V1.79213C0 1.79014 0 1.78855 0 1.78665C0 1.78626 0 1.78586 0 1.78546C0 1.73064 0.00158214 1.677 0.00453563 1.62496C0.00453563 1.62417 0.00453563 1.62337 0.00453563 1.62258C0.00453563 1.62188 0.00453465 1.62108 0.00485109 1.62029C0.00485109 1.61869 0.00485109 1.6172 0.00485109 1.61561C0.00485109 1.61521 0.00485109 1.61481 0.00485109 1.61442C0.036601 1.08387 0.211278 0.669936 0.488167 0.393617L0.565274 0.466653C0.211279 0.767947 0 1.27043 0 1.9375V1.9375ZM0.563164 0.324064L0.566118 0.32128C0.565274 0.322474 0.564008 0.323268 0.563164 0.324064Z"
        fill="url(#paint10_linear_11683_63740)"
      />
      <path
        d="M17.9908 8.6875L2.6804 0.481229C2.25594 0.254264 1.85427 0.146601 1.49658 0.146601C1.13193 0.146601 0.813797 0.258542 0.565388 0.469587L0.488281 0.396551C0.512647 0.372471 0.53733 0.349088 0.563278 0.326998C0.564122 0.326202 0.565388 0.325407 0.566232 0.324611C0.812636 0.115159 1.12739 0.00321831 1.48877 0.00122826C1.48962 0.00122826 1.49078 0.00122826 1.49204 0.00122826C1.49373 0.00122826 1.49489 0.00122826 1.49658 0.00122826C1.85427 0.00122826 2.25594 0.108888 2.6804 0.335953L18.0893 8.59466L18.0889 8.59496L17.9908 8.6875Z"
        fill="url(#paint11_linear_11683_63740)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11683_63740"
          x1="12.4754"
          y1="1.56301"
          x2="-4.19522"
          y2="19.2353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.00657445" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11683_63740"
          x1="25.1702"
          y1="12.7989"
          x2="-0.353132"
          y2="12.7989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11683_63740"
          x1="15.6679"
          y1="15.0811"
          x2="-6.93873"
          y2="39.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_11683_63740"
          x1="-2.82287"
          y1="-6.9291"
          x2="7.27188"
          y2="3.77223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_11683_63740"
          x1="25.1697"
          y1="12.7989"
          x2="-0.352495"
          y2="12.7989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CCB300" />
          <stop offset="0.4087" stopColor="#CC9700" />
          <stop offset="0.7754" stopColor="#CC8400" />
          <stop offset="1" stopColor="#CC7D00" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_11683_63740"
          x1="15.6686"
          y1="15.0811"
          x2="-6.93797"
          y2="39.0461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CC2E36" />
          <stop offset="1" stopColor="#9C0E4E" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_11683_63740"
          x1="12.4754"
          y1="1.55905"
          x2="-4.1953"
          y2="19.2313"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008DE0" />
          <stop offset="0.00657445" stopColor="#008DE0" />
          <stop offset="0.2601" stopColor="#00A7E0" />
          <stop offset="0.5122" stopColor="#00B8E0" />
          <stop offset="0.7604" stopColor="#00C4E0" />
          <stop offset="1" stopColor="#00C7E0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_11683_63740"
          x1="15.6694"
          y1="15.0772"
          x2="-6.93874"
          y2="39.0434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0333C" />
          <stop offset="1" stopColor="#AB0F56" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_11683_63740"
          x1="25.1688"
          y1="12.7974"
          x2="-0.354603"
          y2="12.7974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0C500" />
          <stop offset="0.4087" stopColor="#E0A600" />
          <stop offset="0.7754" stopColor="#E09100" />
          <stop offset="1" stopColor="#E08900" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_11683_63740"
          x1="25.1692"
          y1="12.7979"
          x2="-0.354135"
          y2="12.7979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE840" />
          <stop offset="0.4087" stopColor="#FFCE40" />
          <stop offset="0.7754" stopColor="#FFBC40" />
          <stop offset="1" stopColor="#FFB540" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_11683_63740"
          x1="12.4754"
          y1="1.55959"
          x2="-4.19526"
          y2="19.2319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40B8FF" />
          <stop offset="0.00657445" stopColor="#40B9FF" />
          <stop offset="0.2601" stopColor="#40CEFF" />
          <stop offset="0.5122" stopColor="#40DDFF" />
          <stop offset="0.7604" stopColor="#40E7FF" />
          <stop offset="1" stopColor="#40EAFF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_11683_63740"
          x1="-2.82194"
          y1="-6.92853"
          x2="7.27282"
          y2="3.7728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65B895" />
          <stop offset="0.0685" stopColor="#62BD95" />
          <stop offset="0.4762" stopColor="#50DB97" />
          <stop offset="0.8009" stopColor="#44ED98" />
          <stop offset="1" stopColor="#40F498" />
        </linearGradient>
      </defs>
    </svg>
  );
}
