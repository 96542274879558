/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function AppStoreIcon({
  width = 33,
  height = 33,
  fill = 'none',
  style,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M23.2779 17.7191C23.2401 13.5292 26.7184 11.4908 26.8774 11.3959C24.9075 8.53203 21.8542 8.14073 20.7813 8.10953C18.2169 7.84043 15.7294 9.63963 14.4231 9.63963C13.0907 9.63963 11.0791 8.13553 8.91107 8.17973C6.12115 8.22263 3.51113 9.83333 2.07967 12.3345C-0.874525 17.4344 1.32873 24.9289 4.15908 29.0512C5.5749 31.0701 7.2293 33.3243 9.39475 33.245C11.5133 33.1579 12.3046 31.8982 14.8612 31.8982C17.3943 31.8982 18.1374 33.245 20.3459 33.1943C22.6195 33.1579 24.051 31.1663 25.4173 29.1292C27.0534 26.8152 27.7105 24.5363 27.7366 24.4193C27.6831 24.4011 23.3209 22.741 23.2779 17.7191Z"
        fill={fill}
      />
      <path
        d="M19.106 5.39773C20.2455 3.97683 21.0251 2.04373 20.8087 0.0820312C19.1595 0.154831 17.097 1.21953 15.9094 2.60923C14.8586 3.83383 13.9199 5.84103 14.1624 7.72863C16.015 7.86643 17.9171 6.79653 19.106 5.39773Z"
        fill={fill}
      />
    </svg>
  );
}
